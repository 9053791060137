const InsNew = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM9.1807 8H22.8087C23.4616 8 23.9926 8.51539 23.9926 9.15259V22.84C23.9926 23.4741 23.4616 23.9926 22.8087 23.9926H9.1807C8.52788 23.9926 8 23.4741 8 22.8369V9.15259C8 8.51539 8.52788 8 9.1807 8ZM10.3708 13.9941V21.628H12.7447V13.9941H10.3708ZM10.1802 11.5796C10.1802 12.3386 10.7956 12.9539 11.5577 12.9539C12.3167 12.9539 12.9321 12.3355 12.9321 11.5796C12.9321 10.8206 12.3167 10.2052 11.5577 10.2052C10.7956 10.2052 10.1802 10.8206 10.1802 11.5796ZM19.2573 21.628H21.628V17.4394C21.628 15.3841 21.1845 13.8036 18.7825 13.8036C17.6299 13.8036 16.8553 14.4376 16.5398 15.0374H16.5085V13.9941H14.2346V21.628H16.6023V17.8548C16.6023 16.8584 16.7897 15.8932 18.0235 15.8932C19.2416 15.8932 19.2573 17.0333 19.2573 17.9173V21.628Z"
        fill="white"
      />
    </svg>
  )
}

export default InsNew
